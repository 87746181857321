export enum ExportTypes {
    'klic' = 'klic',
    'risicogrid' = 'risicogrid'
}

export const enum FMEDownloadTypes {
    'shapefile' = 'shapefile',
    'dwg' = 'dwg',
    'geopackage' = 'geopackage',
    'dxf' = 'dxf'
}

export enum ExportFormats {
    DWG = 'DWG',
    DXF = 'DXF',
    Shapefile = 'Shapefile',
    Geopackage = 'Geopackage',
    'Oorspronkelijke melding' = 'Oorspronkelijke melding',
    PDF = 'PDF'
}

export const ExportConfigurations = [
    {
        name: 'nlcs',
        alias: 'NLCS'
    },
    {
        name: 'objecttype',
        alias: 'Objecttype'
    },
    {
        name: 'thema',
        alias: 'Thema'
    },
    {
        name: 'netbeheerder',
        alias: 'Netbeheerder'
    },
    {
        name: 'objecttype_thema',
        alias: 'Objecttype + Thema'
    },
    {
        name: 'objecttype_netbeheerder',
        alias: 'Objecttype + Netbeheerder'
    },
    {
        name: 'netbeheerder_thema',
        alias: 'Netbeheerder + Thema'
    },
    {
      name: 'objecttype_thema_netbeheerder',
      alias: 'Objecttype + Thema + Netbeheerder'
    }
] as const;

export type ExportConfiguration = typeof ExportConfigurations[number]['name'];

export const ExportFilters = [
    {
        name: 'punten',
        alias: 'Puntobjecten',
        defaultValue: true
    },
    {
        name: 'lijnen',
        alias: 'Lijnobjecten',
        defaultValue: true
    },
    {
        name: 'vlakken',
        alias: 'Vlakobjecten',
        defaultValue: true
    },
    {
        name: 'luchtfoto',
        alias: 'Luchtfoto',
        defaultValue: true
    },
    {
        name: 'extra_geometrie',
        alias: 'Extra geometrieën',
        defaultValue: true
    },
    {
        name: 'extra_detail',
        alias: 'Extra details',
        defaultValue: true
    },
    {
        name: 'annotatie',
        alias: 'Annotatie en maatvoering objecten',
        defaultValue: false
    },
    {
        name: 'proefsleuf',
        alias: 'Proefsleuven',
        defaultValue: false
    },
    {
        name: 'ontwerp',
        alias: 'Ontwerplaag',
        defaultValue: false
    }
] as const;

export type ExportFilter = typeof ExportFilters[number]['name'];
